import { GraphQLResult } from "@aws-amplify/api";

const Concurrency = 6;

/**
 * GraphQL 関連のユーティリティを提供します。
 */
export const GraphQLUtils = {
    /**
     * もしもエラーがあれば投げます。
     *
     * @param result 結果
     */
    throwIfError(result: GraphQLResult): void {
        if (result.errors && result.errors.length > 0) {
            if (result.errors.length === 1) {
                throw result.errors[0];
            }

            throw new AggregateError(result.errors);
        }
    },

    /**
     * 並列に入力値を処理します。
     *
     * 最大並列数は 6 です。
     * (HTTP/1.1 のコネクション数が 6 なので)
     *
     * @param inputs 入力リスト
     * @param action アクション
     */
    async runConcurrently<T>(
        inputs: Iterable<T>,
        action: (input: T) => Promise<void>,
    ): Promise<void> {
        const queue = [...inputs].reverse();
        const errors: unknown[] = [];

        await Promise.all(
            Array.from({ length: Concurrency }, async () => {
                let input = queue.pop();
                while (input !== undefined) {
                    try {
                        await action(input);
                    } catch (error: unknown) {
                        if (error instanceof AggregateError) {
                            errors.push(...error.errors);
                        } else {
                            errors.push(error);
                        }
                    }
                    input = queue.pop();
                }
            }),
        );

        if (errors.length === 1) {
            throw errors[0];
        } else if (errors.length > 1) {
            throw new AggregateError(errors);
        }
    },
};
