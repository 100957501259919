import { API, graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import {
    CreateNodeInput,
    CreateNodeMutation,
    Datum,
    DeleteNodeInput,
    Node,
    UpdateNodeInput,
} from "../API";
import { GraphQLUtils } from "../common/graphql-utils";
import { createNode, deleteNode, updateNode } from "../graphql/mutations";
import { DatumController } from "./datum-controller";

export namespace NodeController {
    /**
     * {@link NodeController.deleteNode} 関数のオプション
     */
    export interface DeletionOptions {
        /**
         * 子ノードを取得する関数
         *
         * この関数を与えると子ノードも再帰的に削除します。
         *
         * @default undefined
         */
        getChildNodes?: (nodeId: Node["id"]) => Iterable<Node>;
        /**
         * データを取得する関数
         *
         * この関数を与えるとデータも削除します。
         *
         * {@link getChildNodes} 関数と同時に与えた場合は、子ノードのデータも削
         * 除されます。
         *
         * @default undefined
         */
        getDatums?: (nodeId: Node["id"]) => Iterable<Datum>;
    }
}

/**
 * ノードを操作する処理を定義します。
 */
export const NodeController = {
    /**
     * ノードを作成します。
     *
     * @param input 作成するノードの ID
     */
    async createNode(input: CreateNodeInput): Promise<CreateNodeMutation> {
        const result = (await API.graphql(
            graphqlOperation(createNode, { input }),
        )) as GraphQLResult;

        GraphQLUtils.throwIfError(result);

        return result.data as CreateNodeMutation;
    },

    /**
     * ノードを削除します。
     *
     * オプションで子ノード・所属 Datum も一緒に削除できます。
     *
     * @param nodeId 削除するノードの ID
     * @param options オプション
     */
    async deleteNode(
        nodeId: Node["id"],
        options: NodeController.DeletionOptions = {},
    ): Promise<void> {
        const { getChildNodes, getDatums } = options;
        const allNodeIds =
            getChildNodes === undefined
                ? [nodeId]
                : collectDescendantNodeIds(nodeId, getChildNodes);
        const allDatumIds = new Set<Datum["id"]>();

        // 削除する全データを集める
        if (getDatums !== undefined) {
            for (const nodeId of allNodeIds) {
                for (const datum of getDatums(nodeId)) {
                    allDatumIds.add(datum.id);
                }
            }
        }

        // 削除する
        await GraphQLUtils.runConcurrently(allNodeIds, (nodeId) =>
            deleteNode0(nodeId),
        );
        await DatumController.deleteDatums(allDatumIds);
    },

    /**
     * ノードを削除します。
     *
     * オプションで子ノード・所属 Datum も一緒に削除できます。
     *
     * @param nodeIds 削除するノードの ID
     * @param options オプション
     */
    async deleteNodes(
        nodeIds: Iterable<Node["id"]>,
        options: NodeController.DeletionOptions = {},
    ): Promise<void> {
        const { getChildNodes, getDatums } = options;
        const allNodeIds = new Set<Node["id"]>();
        const allDatumIds = new Set<Datum["id"]>();

        // 削除する全ノードを集める
        if (getChildNodes !== undefined) {
            for (const nodeId of nodeIds) {
                collectDescendantNodeIds(
                    nodeId,
                    getChildNodes,
                    /* Out */ allNodeIds,
                );
            }
        } else {
            for (const nodeId of nodeIds) {
                allNodeIds.add(nodeId);
            }
        }

        // 削除する全データを集める
        if (getDatums !== undefined) {
            for (const nodeId of allNodeIds) {
                for (const datum of getDatums(nodeId)) {
                    allDatumIds.add(datum.id);
                }
            }
        }

        // 削除する
        await GraphQLUtils.runConcurrently(allNodeIds, (nodeId) =>
            deleteNode0(nodeId),
        );
        await DatumController.deleteDatums(allDatumIds);
    },

    /**
     * ノードを更新します。
     *
     * @param input 更新内容
     */
    async updateNode(input: UpdateNodeInput): Promise<void> {
        const result = (await API.graphql(
            graphqlOperation(updateNode, { input }),
        )) as GraphQLResult;

        GraphQLUtils.throwIfError(result);
    },

    /**
     * ノードを更新します。
     *
     * @param inputs 更新内容
     */
    async updateNodes(inputs: Iterable<UpdateNodeInput>): Promise<void> {
        await GraphQLUtils.runConcurrently(inputs, (input) =>
            this.updateNode(input),
        );
    },
};

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

/**
 * 指定したノード ID を起点に、その子ノードの ID を再帰的に収集します。
 *
 * @param nodeId ノード ID
 * @param getChildNodes 子ノードを取得する関数
 * @param outDescendantNodeIds 結果の出力先集合 (省略すると新しい空集合)
 * @returns 起点となるノード ID とその子孫ノード ID をすべて含む集合
 */
function collectDescendantNodeIds(
    nodeId: Node["id"],
    getChildNodes: (nodeId: Node["id"]) => Iterable<Node>,
    outDescendantNodeIds = new Set<Node["id"]>(),
): Set<Node["id"]> {
    if (outDescendantNodeIds.has(nodeId)) {
        return outDescendantNodeIds;
    }

    outDescendantNodeIds.add(nodeId);
    for (const child of getChildNodes(nodeId)) {
        collectDescendantNodeIds(child.id, getChildNodes, outDescendantNodeIds);
    }

    return outDescendantNodeIds;
}

async function deleteNode0(nodeId: Node["id"]): Promise<void> {
    const input: DeleteNodeInput = { id: nodeId };
    const result = (await API.graphql(
        graphqlOperation(deleteNode, { input }),
    )) as GraphQLResult;

    GraphQLUtils.throwIfError(result);
}
