import { API, graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import {
    CreateDatumInput,
    CreateDatumMutation,
    Datum,
    DeleteDatumInput,
    UpdateDatumInput,
} from "../API";
import { GraphQLUtils } from "../common/graphql-utils";
import { createDatum, deleteDatum, updateDatum } from "../graphql/mutations";

/**
 * データを操作する処理を定義します。
 */
export const DatumController = {
    /**
     * データを作成します。
     *
     * @param input 作成するデータの ID
     */
    async createDatum(input: CreateDatumInput): Promise<CreateDatumMutation> {
        const result = (await API.graphql(
            graphqlOperation(createDatum, { input }),
        )) as GraphQLResult;

        GraphQLUtils.throwIfError(result);

        return result.data as CreateDatumMutation;
    },

    /**
     * データを削除します。
     *
     * @param datumId 削除するデータの ID
     */
    async deleteDatum(datumId: Datum["id"]): Promise<void> {
        const input: DeleteDatumInput = { id: datumId };
        const result = (await API.graphql(
            graphqlOperation(deleteDatum, { input }),
        )) as GraphQLResult;

        GraphQLUtils.throwIfError(result);
    },

    /**
     * データを削除します。
     *
     * @param datumIds 削除するデータの ID
     */
    async deleteDatums(datumIds: Iterable<Datum["id"]>): Promise<void> {
        await GraphQLUtils.runConcurrently(datumIds, (datumId) =>
            this.deleteDatum(datumId),
        );
    },

    /**
     * データを更新します。
     *
     * @param input 更新内容
     */
    async updateDatum(input: UpdateDatumInput): Promise<void> {
        const result = (await API.graphql(
            graphqlOperation(updateDatum, { input }),
        )) as GraphQLResult;

        GraphQLUtils.throwIfError(result);
    },

    /**
     * データを更新します。
     *
     * @param inputs 更新内容
     */
    async updateDatums(inputs: Iterable<UpdateDatumInput>): Promise<void> {
        await GraphQLUtils.runConcurrently(inputs, (input) =>
            this.updateDatum(input),
        );
    },
};
